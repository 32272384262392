import mapCoverDetails from 'api/quote/quoteToFormMappings/mapCoverDetails';
import mapDriverDetails from 'api/quote/quoteToFormMappings/mapDriverDetails';
import mapVehicleDetails from 'api/quote/quoteToFormMappings/mapVehicleDetails';
import quoteClient from 'api/quoteClient';
import quoteInProgressClient from 'api/quoteInProgressClient';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useReducer } from 'react';
import { isAxiosError } from 'helpers/axiosResponseHelpers';
import { getQueryParam } from 'helpers/getQueryParam';
import { useBaseApiRequestHandler } from 'helpers/useApiRequestHandler';
import useFormRedirection from 'helpers/useFormRedirection';
import {
  initialAdditionalDriversDetails,
  useAdditionalDriversDetails,
} from 'state/formData/additionalDriversDetails';
import { initialCoverDetails, useCoverDetails } from 'state/formData/coverDetails';
import { initialDriverDetails, useDriverDetails } from 'state/formData/driverDetails';
import { UPDATE_QUOTE_IN_PROGRESS } from 'state/formData/quoteInProgress';
import { initialVehicleDetails, useVehicleDetails } from 'state/formData/vehicleDetails';
import { UPDATE_QUOTE, useQuote } from 'state/quote/quote';
import useDispatch from 'state/useDispatch';

/**
 * Initialises the Quote state in redux on page load, and handles redirection (if required) for
 * new quote form data. This is used for the details capture pages.
 */
const LoadQuoteInProgressWrapper: React.FC<{ location: Location }> = ({
  location,
  children,
}) => {
  const quote = useQuote();
  const dispatch = useDispatch();
  const requestHandler = useBaseApiRequestHandler();
  const quoteNumber = getQueryParam(location, 'quoteNumber');
  const [hasInitialisedSessionData, setHasInitialisedSessionData] = useReducer(
    () => true,
    false
  );

  const handleFormRedirection = useFormRedirection(location);

  const [vehicleDetails] = useVehicleDetails();
  const [driverDetails] = useDriverDetails();
  const [coverDetails] = useCoverDetails();
  const [additionalDriverDetails] = useAdditionalDriversDetails();

  // In a single visit to the site (between refreshes/hitting the server) you will only ever need to initialise
  // the session quote in progress once. There are two scenarios:
  // 1) landing on a current session quote page first (quote summary, check details, payment)
  //   - in this case the quote will have been initialised by the QuoteWrapper, and hence the form data populated.
  //   - the form data will be different to the initial values in that case, and hence this initialisation will not run.
  // 2) landing on a current session form page first (about your car, about you, about your cover, additional drivers)
  //   - in this case we will initialise the data as part of this component then set the initialised flag so it
  //     doesn't reload again, even if the form data has not been changed.
  // If the user ever loads the site with a quoteNumber query param then there is no way to get back to editing a session
  // quote doing a reload (there are no internal nav links for that) except via 'start a quote' which resets the quote state
  // anyway, and we don't need to worry about the form initialising again in that case.
  // To do something more precise, we'd likely need to add something like '/current' into the URLs so that we can know
  // whether the quote we have loaded is in from the session or an explicit load.
  const shouldLoadSessionQuote =
    !hasInitialisedSessionData &&
    isEqual(vehicleDetails, initialVehicleDetails) &&
    isEqual(driverDetails, initialDriverDetails) &&
    isEqual(coverDetails, initialCoverDetails) &&
    isEqual(additionalDriverDetails, initialAdditionalDriversDetails);

  const loadSessionQuoteProgress = useCallback(async (): Promise<void> => {
    if (quote) {
      dispatch({ type: UPDATE_QUOTE, quote: null });
    }

    await requestHandler(async () => {
      try {
        const fetchedQuote = await quoteClient.getCurrentQuote();
        dispatch({ type: UPDATE_QUOTE, quote: fetchedQuote });
        handleFormRedirection(
          mapVehicleDetails(fetchedQuote),
          mapDriverDetails(fetchedQuote),
          mapCoverDetails(fetchedQuote)
        );
      } catch (error) {
        // If there is no quote, then use any form data saved in the quote in progress.
        if (isAxiosError(error) && error.response.status === 404) {
          const quoteInProgress = await quoteInProgressClient.loadQuoteInProgress();
          dispatch({ type: UPDATE_QUOTE_IN_PROGRESS, quote: quoteInProgress });
          setHasInitialisedSessionData();
          handleFormRedirection(
            quoteInProgress.vehicleDetails,
            quoteInProgress.driverDetails,
            quoteInProgress.policyDetails
          );
        } else {
          throw error;
        }
      }
    });
  }, [dispatch, handleFormRedirection, quote, requestHandler]);

  useEffect(() => {
    if (quoteNumber && quote?.quoteNumber !== quoteNumber) {
      requestHandler(async () => {
        const fetchedQuote = await quoteClient.getQuote(quoteNumber);
        dispatch({ type: UPDATE_QUOTE, quote: fetchedQuote });
        handleFormRedirection(
          mapVehicleDetails(fetchedQuote),
          mapDriverDetails(fetchedQuote),
          mapCoverDetails(fetchedQuote)
        );
      });
    } else if (shouldLoadSessionQuote) {
      loadSessionQuoteProgress();
    }
  }, [
    shouldLoadSessionQuote,
    loadSessionQuoteProgress,
    quoteNumber,
    quote,
    requestHandler,
    dispatch,
    handleFormRedirection,
  ]);

  return <>{children}</>;
};

export default LoadQuoteInProgressWrapper;
